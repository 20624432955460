
export default {
  data() {
    return {
      izjave: [
        {
          ime: 'Anita K.',
          tekst:
            'Sve pohvale za rad, brzinu izvedbe, urednost i izuzetno ljubaznu komunikaciju. Preporučujem!',
          izvor: 'Google Maps',
        },

        {
          ime: 'Branimir I.',
          tekst:
            'U vrlo kratkom roku popravljena LED rasvjeta koja nije radila pola godine. Topla preporuka.',
          izvor: 'Google Maps',
        },

        {
          ime: 'Boris L.',
          tekst:
            'Nakon dva mjeseca traženja električara za jedan dan posla oko priključka vikendice i reorganizacije instalacija te ormarićem za osigurače, nazvao LightHaus, dogovorio se za posao u vrlo kratkom roku, posao obavljen vrlo profesionalno i cijena vrlo pristupačna.',
          izvor: 'Google Maps',
        },

        {
          ime: 'Jurica M.',
          tekst:
            'Odličan majstor, za svaku preporuku. Dogovorili smo se oko radova u par minuta, već sljedeći dan je bio kod mene. Izmještanje instalacija odrađeno točno kako sam zamislio, sve radi besprijekorno.',
          izvor: 'trebam.hr',
        },

        {
          ime: 'Mirela G.',
          tekst: 'Posao je obavljen brzo i kvalitetno. Iznimno sam zadovoljna.',
          izvor: 'trebam.hr',
        },
      ],
      zvjezdice: [1, 2, 3, 4, 5],
    }
  },

  mounted() {
    const kontejner = this.$refs.izjaveKontejner
    const izjave = this.$refs.izjaveAnimiraj
    const zaMob = this.$refs.zaMob

    if(this.$device.isMacOS || this.$device.isIos) {
      this.$gsap.set('.izjava-i', {height: document.querySelector('.izjava-i').clientWidth})
    }


    if (this.$device.isMobileOrTablet) {
      kontejner.style.minHeight = window.innerHeight + "px";
      zaMob.classList.add('mobile-scroll-c')
      izjave.classList.add('mobile-scroll-i')
    }
    else {
      this.$gsap.to(izjave, {
        xPercent: -80,
        ease: 'none',
        scrollTrigger: {
          id: 'izjave',
          pin: kontejner,
          trigger: kontejner,
          start: 'left left',
          end: () => `+=${izjave.scrollWidth} bottom`,
          scrub: 1,
        },
      })
    }
  },
  beforeDestroy() {
    if (this.$ScrollTrigger.getById('izjave')) {
      this.$ScrollTrigger.getById('izjave').kill()
    }
  },
}
