import { render, staticRenderFns } from "./index.vue?vue&type=template&id=66a639f8&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=66a639f8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66a639f8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PocetnaHero: require('D:/lighthaus/lighthaus-web-production/lh/components/PocetnaHero.vue').default,PocetnaUsluge2: require('D:/lighthaus/lighthaus-web-production/lh/components/PocetnaUsluge2.vue').default,PocetnaIzjave: require('D:/lighthaus/lighthaus-web-production/lh/components/PocetnaIzjave.vue').default,PocetnaNasaMisija: require('D:/lighthaus/lighthaus-web-production/lh/components/PocetnaNasaMisija.vue').default,PocetnaFaq2: require('D:/lighthaus/lighthaus-web-production/lh/components/PocetnaFaq2.vue').default,PocetnaKontakt: require('D:/lighthaus/lighthaus-web-production/lh/components/PocetnaKontakt.vue').default})
