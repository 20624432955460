
export default {
  data() {
    return {
      anPlayed: false,
      ucitano: false,
      tl: null
    }
  },
  watch: {
    ucitano: function() {
      this.tl = this.$gsap.timeline({onComplete: () => {this.anPlayed = true}});
    this.tl.to('.h-s', {opacity: 1, y: 0, duration: 1, ease:'curve'}, 'l')
    this.tl.to('.h-s-s-d', {opacity: 1, y: 0, duration: .5, ease:'curve'})
    this.tl.to('.pe img', {opacity: 1, x: 0 , duration: 1, ease: 'curve', stagger: .2}, 'l')
    }
  },
  mounted() {

  },
  methods: {
    plx(e) {
      if (this.$device.isDesktop && this.anPlayed) {
        this.$gsap.to('#pe-fg', {
          x: e.clientX / 20,
          y: e.clientY / 60,

        })
        this.$gsap.to('#pe-md', {
          x: e.clientX / 40,
          y: e.clientY / 80,

        })
        this.$gsap.to('#pe-bg', {
          x: e.clientX / 60,
          y: e.clientY / 100,

        })
      }
    },
  },
}
