
export default {
  head() {
    return {
      title: 'LightHaus',
       link: [
      { rel: 'canonical', href: 'https://lighthaus.hr' },
    ],
    }
  },
  mounted() {
    if(history.scrollRestoration) {
      history.scrollRestoration = 'manual'
    }
    this.$ScrollTrigger.refresh()
    const an = this.$gsap.timeline({ paused: true })
    const an2 = this.$gsap.timeline({ paused: true })



    an.fromTo(
      '.ns-2, .ns-1',
      {
        autoAlpha: 0,
        yPercent: 100,
      },
      {
        rotationZ: 0.01,
        autoAlpha: 1,
        duration: 1,
        z: 0.1,
        yPercent: 0,
        ease: 'curve',
        stagger: {
          each: 0.3,
          from: 'end',
        },
      }
    )

    this.$ScrollTrigger.create({
      trigger: '.ns-1',
      start: 'bottom bottom',
      animation: an,
    })

    an2.fromTo(
      '.ns-3, .ns-4',
      {
        autoAlpha: 0,
        yPercent: 100,
      },
      {
        rotationZ: 0.01,
        autoAlpha: 1,
        duration: 1,
        z: 0.1,
        yPercent: 0,
        ease: 'curve',
        stagger: {
          each: 0.3,
          from: 'end',
        },
      }
    )

    this.$ScrollTrigger.create({
      trigger: '.ns-3',
      start: 'bottom bottom',
      animation: an2,
    })
  },

  beforeDestroy() {
    this.$ScrollTrigger.getAll().forEach((st) => st.kill())
  },

}
