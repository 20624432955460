
export default {
  mounted() {
    const tl = this.$gsap.timeline({ paused: true })

    tl.fromTo('.nm-e', { yPercent: 120 }, { yPercent: -20 }, 'l').fromTo(
      '.nm-v',
      { yPercent: 50 },
      { yPercent: 0 },
      'l'
    )

    this.$ScrollTrigger.create({
      trigger: '.vizual',
      start: 'top bottom',
      end: 'bottom 35%',
      toggleActions: 'play none play reverse',
      scrub: true,
      animation: tl,
      id: 'nm',
    })
  },
}
