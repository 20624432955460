import { render, staticRenderFns } from "./PocetnaHero.vue?vue&type=template&id=e3ce26b6&scoped=true&"
import script from "./PocetnaHero.vue?vue&type=script&lang=js&"
export * from "./PocetnaHero.vue?vue&type=script&lang=js&"
import style0 from "./PocetnaHero.vue?vue&type=style&index=0&id=e3ce26b6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3ce26b6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvgKvacica: require('D:/lighthaus/lighthaus-web-production/lh/components/SvgKvacica.vue').default,SvgTelefon: require('D:/lighthaus/lighthaus-web-production/lh/components/SvgTelefon.vue').default,SvgStrelicaPocetna: require('D:/lighthaus/lighthaus-web-production/lh/components/SvgStrelicaPocetna.vue').default})
