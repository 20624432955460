
export default {
  data() {
    return {
      linkovi: [
        ['Elektro–instalacije', '#elektro-instalacije'],
        ['Montaža / Servis', '#montaza-servis'],
        ['Ugradnja Rasvjete', '#ugradnja-rasvjete'],
        ['SmartHome Sustavi', '#smarthome-sustavi'],
      ],
    }
  },
}
