
export default {
  data() {
    return {
      fadeInElements: null
    }
  },
  mounted() {
    this.fadeInElements = this.$gsap.timeline({ defaults: { ease: 'curve', duration: 1}});

    this.fadeInElements.fromTo('.kfi', {
      opacity: 0,
      yPercent: 100
    }, {opacity: 1, yPercent: 0, rotationZ: 0.01, z: .1, stagger: .3})


          this.$ScrollTrigger.create( {
            trigger: '#p-kontakt',
            start: '60% bottom',
             animation: this.fadeInElements,
          })
  },
  beforeDestroy() {
    this.fadeInElements.kill()
  }
}
