
export default {
  data() {
    return {
      pitanja: [
        [
          { upit: 'Na kojem području pružate svoje usluge?' },
          {
            odg: 'Naše usluge pružamo na području Zagreba i Zagrebačke županije.',
          },
          {
            aktivno: false,
          },
        ],

        [
          { upit: 'Plaća li se dolazak električara?' },
          {
            odg: 'U specifičnim slučajevima – da, kod manjeg opsega posla na daleko udaljenoj lokaciji i kod netočnog predstavljanja hitnosti / problema instalacije.',
          },
          {
            aktivno: false,
          },
        ],

        [
          { upit: 'Kada pozvati električara za hitne popravke?' },
          {
            odg: 'U slučaju kratkog spoja na instalaciji, iznenadnog pregrijavanja električne komponente ili nestanka električne struje.',
          },
          {
            aktivno: false,
          },
        ],

        [
          { upit: 'Koliki je vremenski period dolaska električara?' },
          {
            odg: 'Ovisno o hitnosti / intervenciji, ali u prosjeku u roku od jednog do dva radna dana.',
          },
          {
            aktivno: false,
          },
        ],

        [
          { upit: 'Radite li s privatnim osobama ili s tvrtkama?' },
          {
            odg: 'Naše usluge pružamo privatnim osobama i tvrtkama, odnosno za njihov stambeni ili poslovni prostor.',
          },
          {
            aktivno: false,
          },
        ],

        [
          {
            upit: 'Koliko bi koštala kompletna izvedba elektro-instalacija novogradnje?',
          },
          {
            odg: 'Kako bismo mogli odrediti što točniju cijenu potrebni su nam podaci o sljedećim stavkama: broj rasvjetnih tijela, zahtjevnost instalacije i način ugradnje. Cijena ovisi i o drugim faktorima, ali ovo je dobar početak. ',
          },
          {
            aktivno: false,
          },
        ],
      ],
    }
  },

  mounted() {},

  methods: {
    beforeEnter: function (el) {
      this.$gsap.set(el, { height: 0, opacity: 0, y: 30 })
    },
    enter: function (el, done) {
      const tl = this.$gsap.timeline({ defaults: { ease: 'curve' } })
      tl.to(el, { height: 'auto', duration: 0.4 }).to(
        el,
        {
          opacity: 1,
          y: 0,
          duration: 0.3,
          onComplete: done,
        },
        '<80%'
      )
    },
    leave: function (el, done) {
      const tl = this.$gsap.timeline({ defaults: { ease: 'c-reversed' } })

      tl.to(el, {
        opacity: 0,
        duration: 0.3,
        y: -10,
      }).to(el, { height: 0, duration: 0.4, onComplete: done }, '<80%')
    },
  },
}
