import { render, staticRenderFns } from "./PocetnaFaq2.vue?vue&type=template&id=3ab88754&scoped=true&"
import script from "./PocetnaFaq2.vue?vue&type=script&lang=js&"
export * from "./PocetnaFaq2.vue?vue&type=script&lang=js&"
import style0 from "./PocetnaFaq2.vue?vue&type=style&index=0&id=3ab88754&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ab88754",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LhCrtica: require('D:/lighthaus/lighthaus-web-production/lh/components/LhCrtica.vue').default,SvgPlus: require('D:/lighthaus/lighthaus-web-production/lh/components/SvgPlus.vue').default})
